import React, { useState } from "react"
import Button from "./Button"
import LangSwitch from "./LangSwitch"
import Link from "../contentfulModels/Link"

import "./HeaderStatic.scss"
import "./Hamburger.scss"

const Header = ({ indexPage, dispatch, data, language }) => {
  const [hamburgerToggle, setHamburgerToggle] = useState(false)
  const [headerBackground, setHeaderBackground] = useState(false)

  const onHamburgerToggle = () => {
    setHamburgerToggle(!hamburgerToggle)
  }

  if (typeof document !== "undefined") {
    hamburgerToggle
      ? document.body.classList.add("lock")
      : document.body.classList.remove("lock")
  }

  return (
    <header className={`header ${headerBackground ? "overlay" : ""}`}>
      <div className="header__container">
       
        
        <img
          src={data?.logo?.file?.url}
          loading="lazy"
          alt="Logo"
          className="header__logo"
        />
        
       
        <nav
          className={"header__navigation" + (hamburgerToggle ? " active" : "")}
        >
          <ul className="header__navigation_list">
           
              <Link
                key="home"
                className="header__navigation_list_link"
                href="/"
                indexPage={indexPage}
              >
                Home
              </Link>
           
          </ul>
          <Button styleName="transparent" href={data?.button?.url}>
            {data?.button?.name}
          </Button>
          {/* <LangSwitch dispatch={dispatch} language={language} /> */}
        </nav>
        <div className="hamburger-outter" style={{ marginTop: "10px" }}>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/hamburgers/1.1.3/hamburgers.min.css"
            integrity="sha512-+mlclc5Q/eHs49oIOCxnnENudJWuNqX5AogCiqRBgKnpoplPzETg2fkgBFVC6WYUVxYYljuxPNG8RE7yBy1K+g=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
          <button
            className={
              "hamburger hamburger--vortex" +
              (hamburgerToggle ? " is-active" : "")
            }
            type="button"
            onClick={onHamburgerToggle}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
