import React from "react"
import HeaderStatic from "../components/HeaderStatic"
import SectionTC from "../components/SectionTC"
import Footer from "../components/Footer"
import { IndexQuery } from "../components/Query/IndexPage"
import { connect } from "react-redux"

import "./index-clear.scss"
import "./index.scss"

const TermsPage = ({ language, dispatch }) => {
  const data = IndexQuery()

  let dataLang = {}
  

  data.allContentfulIndex.nodes.forEach(node => {
    if (language === node.node_locale) {
      dataLang = node
    }
  })

  

  return (
    <>
      <HeaderStatic
        indexPage={true}
        data={dataLang.header}
        dispatch={dispatch}
        language={language}
      />
      <main className="content">
        <SectionTC data={dataLang} />

      </main>
      <Footer data={dataLang.footer} />
    </>
  )
}

export default connect(state => state.languageReducer, null)(TermsPage)
