import React from "react"

import "./SectionPolicy.scss"

const SectionTC = ({ data }) => {
  return (
    <section
      className="policy"
     
    >
      <div className="policy__section">
     
        <div className="policy__section_container">
         
          <div className="policy__section_container_mid">
            <div className="policy__section_container_mid">
              <p>{data?.policy?.termsAndConditions.termsAndConditions}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionTC
